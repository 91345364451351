/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import CampHoc from '../../components/CampHoc';

// eslint-disable-next-line react/prop-types
const AlottelijoidenVaellusHettaPallas = ({ data }) => {
  const seo = {
    url: 'https://www.discoverlapland.fi/vaellukset/aloittelijoiden-vaellus-hetta-pallas-2023',
    image: `https://www.discoverlapland.fi${data.imageSEO.childImageSharp.gatsbyImageData.images.fallback.src}`,
    title: 'Aloittelijoiden vaellus Hetta-Pallas 2023',
    description:
      'Onko haaveissasi useamman päivän vaellus Lapissa, mutta jostain syystä et ole vielä päässyt toteuttamaan unelmaasi? Nyt sinulla on mahdollisuus laittaa rinkka selkään ja lähteä oppimaan vaelluksen perusteita legendaariselle Hetta-Pallaksen reitille eräoppaan kanssa.',
  };

  return (
    <CampHoc
      heroImage={data.alottelijoidenVaellusBg.childImageSharp.gatsbyImageData}
      seo={seo}
      camp="aloittelijoiden-vaellus-hetta-pallas-2023"
    >
      <div className="camp-text-content">
        <h2>ALOITTELIJOIDEN VAELLUS HETTA-PALLAS 2023</h2>
        <h3>
          22.-25.6.2023 <br />
          20.-23.7.2023 <br />
          9.-12.9.2023 <br />
          16.-19.9.2023
        </h3>
        <p className="bolded">
          Onko haaveissasi useamman päivän vaellus Lapissa, mutta jostain syystä
          et ole vielä päässyt toteuttamaan unelmaasi? Nyt sinulla on
          mahdollisuus laittaa rinkka selkään ja lähteä oppimaan vaelluksen
          perusteita legendaariselle Hetta-Pallaksen reitille eräoppaan kanssa.
        </p>
        <br />
        <p>
          Hetta-Pallaksen 55km reitti kulkee upeissa Tunturi-Lapin maisemissa
          Suomen kolmanneksi suurimmassa kansallispuistossa. Hetta-Pallas reitti
          linjattiin vuonna 1934. Päivämatkamme ovat 10-15 km välillä ja
          vietämme matkalla kolme yötä omissa teltoissa. Tämä upea ja
          aloittelijoille sopiva reitti tarjoaa matkalla suurpiirteisiä
          tuntureita ja silmänkantamattomiin siintäviä saloja – Sydäntä
          sykähdyttäviä hetkiä!
          <br />
          <br />
          Kuljemme retkellä rauhassa, kansallispuistomaisemista, tunnelmasta
          sekä luonnosta nauttien. Voit lähteä tälle aloittelijoiden
          vaellukselle ystäviesi kanssa tai yksin, tulet joka tapauksessa
          varmasti saamaan uusia tuttavuuksia.
        </p>
        <br />
        <p className="bolded">Ennen retkeä</p>
        <p>
          Lähetämme sinulle tarkan pakkauslistan sekä retkisuunnitelman, jotka
          käymme läpi retkelle lähtijöiden kanssa kahdessa online-tapaamisessa.
        </p>
      </div>
      <GatsbyImage
        image={data.alottelijoidenVaellusImg1.childImageSharp.gatsbyImageData}
        className="offering-img"
        alt="image"
      />
      <div className="camp-text-content">
        <p>
          <span className="bolded">22.-25.6.2023 vaeltajille:</span> <br />
          Ensimmäinen online-tapaaminen (valitse ensimmäisestä
          online-tapaamisesta itsellesi sopiva päivä) 30.3.2023 klo 19-21,
          11.5.2023 klo 19-21 tai 1.6.2023 klo 19-21, toinen online-tapaaminen
          15.6.2023 klo 19-21. <br />
          <span className="bolded">20.-23.7.2023 vaeltajille:</span> <br />
          Ensimmäinen online-tapaaminen (valitse ensimmäisestä
          online-tapaamisesta itsellesi sopiva päivä) 30.3.2023 klo 19-21,
          11.5.2023 klo 19-21 tai 1.6.2023 klo 19-21, toinen online-tapaaminen
          13.7.2023 klo 19-21. <br />
          <span className="bolded">9.-12.9.2023 vaeltajille:</span> <br />
          Ensimmäinen online-tapaaminen (valitse ensimmäisestä
          online-tapaamisesta itsellesi sopiva päivä) 30.3.2023 klo 19-21,
          11.5.2023 klo 19-21, 1.6.2023 klo 19-21 tai 17.8.2023 klo 19-21,
          toinen online-tapaaminen 28.8.2023 klo 19-21. <br />
          <span className="bolded">16.-19.9.2023 vaeltajille:</span> <br />
          Ensimmäinen online-tapaaminen (valitse ensimmäisestä
          online-tapaamisesta itsellesi sopiva päivä) 30.3.2023 klo 19-21,
          11.5.2023 klo 19-21, 1.6.2023 klo 19-21 tai 17.8.2023 klo 19-21,
          toinen online-tapaaminen 4.9.2023 klo 19-21. <br />
          <br />
          Annamme tietoa ja vinkkejä vaellukseen, telttailuun, ruokailuun ja
          vaatetukseen, jotta sinulla on turvallinen sekä luotettava olo lähteä
          ensimmäiselle pitkälle vaellukselle Lappiin. Retkellä on myös oma
          salainen Facebook-ryhmä, jossa osallistujat voivat kysellä mm.
          kimppakyytejä, teltan sekä retkikeittimien jakamista toisen vaeltajan
          kanssa. Näin sinun ei tarvitse kantaa / hankkia kaikkea itse
          vaellukselle.
        </p>
        <br />
        <p className="bolded">Vaelluksen oppaat</p>
        <p>
          Vaelluksilla oppaina toimivat Sanni Lampinen ja Vilma Heikka.
          Muoniossa asuva Sanni on toiselta ammatiltaan fysioterapeutti ja hän
          toimii oppaana myös Discover Laplandin hiihtovaelluksilla. Sanni on
          kiinnostunut ihmisen kokonaisvaltaisesta hyvinvoinnista ja siitä,
          miten voimme luonnossa eheyttää itseämme. Retkeilijänä Sanni on
          nautiskelija ja seikkailija. Hän nauttii ympäröivästä luonnosta, sen
          kauniista yksityiskohdista, rauhasta, eri sääolosuhteista ja kaikesta
          erilaisuudesta mitä luonto meille tarjoaa. Sanni sekä Vilma ovat
          käyneet eräopaskoulunsa Muoniossa. Vilma on kotoisin
          Pohjois-Pohjanmaalta, sukujuuret löytyvät Lapista. Erityisesti
          Pallas-Yllästunturin kansallispuisto on hänelle rakas. Tätä nykyä
          Vilma asuu Ylläksellä. Vilman vaelluksilla vallitsee hyvä ja
          kannustava fiilis ja jokainen saa olla oma itsensä. Vilman löytää
          usein tunturista, talvisin lumilaudan ja kesäisin rinkan kanssa.
        </p>
        <br />
        <p className="bolded">Vaelluksen aikataulu:</p>
        <p>
          Vaelluksen ensimmäisenä päivänä tapaaminen on klo 09.00 Hetassa.
          Hetassa otamme veneen Ounasjärven yli, josta vaellus alkaa. Vaellus
          kestää neljä päivää ja matkalla nukumme teltoissa kolme yötä. Vaellus
          päättyy sunnuntaina Pallakselle, jossa olemme noin klo 15.00. <br />
          <br /> Jos olet tulossa omalla autolla, voit ostaa Hetasta
          autonsiirtopalvelun eli he ajavat autosi Hetasta Pallakselle
          odottamaan. Tämä maksaa 100€.
        </p>
        <br />
        <p className="bolded">Mitä tarvitset mukaasi vaellukselle?</p>
        <p>
          - Vaellukseen sekä eri säihin sopivat vaatteet ja kengät <br />
          - Teltta, makuualustat sekä makuupussi <br />
          - Rinkka <br />- Ruuantekovälineet, ruokaa, vesipullo / termospullo
        </p>
      </div>
      <GatsbyImage
        image={data.alottelijoidenVaellusImg2.childImageSharp.gatsbyImageData}
        className="offering-img"
        alt="image"
      />
      <div className="camp-text-content">
        <p className="bolded">Vaatimustaso:</p>
        <p>
          Fyysisen kunnon puolesta sinun tulee olla hyvässä kunnossa eli vaellus
          vaatii hyvää kuntoa. Reitillä on lukuisia pitkiä nousuja ja laskuja,
          aika-ajoin hyvinkin kivikkoisessa maastossa ja tämä kaikki painava
          rinkka selässä. Lähetämme sinulle valmennusohjelman, joka osaltaan
          auttaa sinua osaltaan fyysisesti valmistautumaan vaellukseen hyvissä
          ajoin, mutta lähtökohtaisesti kuntosi pitää olla jo valmiiksi hyvä.
          Kyseessä on aloittelijoiden vaellus eli sinulla ei tarvitse olla
          vaelluksesta aikaisempaa kokemusta.
        </p>
        <br />
        <p className="bolded">Vaelluksen hinta on 350€ (sis alv.)</p>
        <p>
          Hintaan kuuluu: <br />
          Pakkauslista, päiväkohtainen retkisuunnitelma ja valmennusohjelma
          pitkää vaellusta varten Opas neljäksi päiväksi. Ennen retkeä:
          Osallistujan kanssa retkelle valmistautuminen kahdessa eri
          online-tapaamisissa, jossa käymme läpi tarvittavat vaellustarvikkeet,
          vaatetuksen, ruoka sekä sen laitto sekä tarvittava apu Lappiin
          saapumiseen ja majoittautumiseen.
          <br /> <br />
          Tämän lisäksi sinulle tulee kustannettavaksi venekuljetus Hetassa,
          joka on 10€ sekä Hannukurun saunamaksu 7€.
        </p>
        <br />
        <p className="bolded">
          Ilmoittaudu mukaan sähköpostitse{' '}
          <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>{' '}
          ja toimi näin:
        </p>
        <p>
          OTSIKKO: ALOITTELIJOIDEN VAELLUS HETTA-PALLAS ja mille vaellukselle
          osallistut - päivämäärät <br />
          Vastaa meilissä ao. kysymyksiin: <br />
          Koko nimi: <br />
          Ikä: <br />
          Puhelinnumero: <br />
          Paikkakunta: <br />
          Mikä on aikaisempi kokemuksesi luonnossa liikkumisesta? <br />
          Mikä on kuntotasosi ja kuina paljon ja miten liikut normaalisti
          arjessa? <br />
          Mikä on terveydentilasi (onko polvi/nivelongelmia, astmaa jne.)?
        </p>
        <br />
        <p className="bolded">Retken varaus- ja peruutusehdot:</p>
        <p>
          Kun olet ilmoittautunut mukaan sähköpostitse, saat paluupostina
          laskun. Kun lasku on maksettu, on paikka vaellukselle varattu ja saat
          jälleen paluupostina vaelluksen materiaalit.
          <br />
          <br />
          Jos perut osallistumisesi vaellukselle missä kohtaa tahansa, pidätämme
          maksetusta laskusta toimitetut vaelluksen materiaalien kulut sekä
          toimistokulut 175€. Ilmoittautumisen peruutus tulee tehdä
          sähköpostitse osoitteeseen{' '}
          <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>
          .
          <br />
          <br />
          Jos perut retken 60 päivää ennen retken aloituspäivämäärää, palautamme
          sinulle 175€. Jos perut retken alle 60 päivää, kuitenkin 30 päivää
          ennen retken aloituspäivää, palautamme sinulle 87,50€. Jos perut
          retken 29 päivää tai alle ennen retken aloituspäivämäärää, ei
          palautusta.
          <br />
          <br />
          Lue lisää varaus – ja peruutusehdoistamme{' '}
          <Link className="bolded" to="/varaus-ja-peruutusehdot">
            tästä
          </Link>
          .
          <br />
          <br />
          HUOM! Pidäthän huolen, että sinulla on voimassa oleva
          vapaa-ajanvakuutus vaelluksen aikana.
          <br />
          <br />
          Vaellukselle otetaan maksimissaan 8 naista, minimi henkilömäärä on 5,
          jotta retki toteutuu.
          <br />
          <br />
          Hetta-Pallas vaelluksen jälkeen sinun on helppo lähteä toteuttamaan
          omia vaelluksiasi. Tervetuloa maagiseen Lappiin viettämään unohtumaton
          naisten vaellus!
          <br />
          <br />
          Tekisikö mieli mukaan tälle ainutlaatuiselle vaellukselle? Mikäli
          sinulla on kysyttävää vaelluksesta, voit olla yhteydessä Anetteen joko
          meilitse{' '}
          <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>{' '}
          tai soittamalla 040 772 4124.
        </p>
      </div>
    </CampHoc>
  );
};

export const query = graphql`
  {
    alottelijoidenVaellusBg: file(
      relativePath: { eq: "images/hikes/2022-hetta-pallas-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    alottelijoidenVaellusImg1: file(
      relativePath: { eq: "images/hikes/hetta-pallas-2020-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    alottelijoidenVaellusImg2: file(
      relativePath: { eq: "images/hikes/hetta-pallas-2020-2.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    imageSEO: file(
      relativePath: { eq: "images/hikes/2022-hetta-pallas-1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1366, layout: FIXED)
      }
    }
  }
`;

export default AlottelijoidenVaellusHettaPallas;
